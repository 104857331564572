@use "sass:meta" as ---886wn800nib;// landing typography
@mixin hyphens-auto {
  :global(.hyphens) {
    hyphens: auto;
  }
}

@mixin text-landing-h1 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(80);
  font-weight: $font-weight_extra-bold;
  line-height: 1em;
  letter-spacing: -0.03em;

  &:global(._tablet) {
    font-size: rem-calc(56);
  }

  @include breakpoint(medium down) {
    font-size: rem-calc(56);
  }

  &:global(._mobile) {
    font-size: rem-calc(32);
  }

  @include breakpoint(small down) {
    font-size: rem-calc(32);
  }
}

@mixin text-landing-h2 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(32);
  font-weight: $font-weight_bold;
  line-height: 1.35em;
  letter-spacing: -0.03em;

  @include breakpoint(small down) {
    font-size: rem-calc(24);
  }
}

@mixin text-landing-h3 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(24);
  font-weight: $font-weight_bold;
  line-height: 1.35em;
  letter-spacing: -0.03em;

  @include breakpoint(small down) {
    font-size: rem-calc(20);
  }
}

@mixin text-landing-h4 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(20);
  font-weight: $font-weight_bold;
  line-height: 1.35em;
  letter-spacing: -0.03em;

  @include breakpoint(small down) {
    font-size: rem-calc(18);
  }
}

@mixin text-landing-h5 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(18);
  font-weight: $font-weight_bold;
  line-height: 1.35em;
  letter-spacing: -0.03em;

  @include breakpoint(small down) {
    font-size: rem-calc(16);
  }
}

@mixin text-landing-h6 {
  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_bold;
  line-height: 1.35em;
  letter-spacing: -0.03em;
}

@mixin text-landing-main {
  color: $c-gray-60;

  @include hyphens-auto;
  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_medium;
  line-height: 1.5em;
}

// platform typography
@mixin text-platform-main {
  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_medium;
  line-height: 1.5em;
}

@mixin text-platform-secondary {
  font-family: $f-manrope;
  font-size: rem-calc(14);
  font-weight: $font-weight_regular;
  line-height: 1.57em;
}

@mixin text-platform-small {
  font-family: $f-manrope;
  font-size: rem-calc(12);
  font-weight: $font-weight_regular;
  line-height: 1.67em;
}

@mixin text-platform-h1 {
  font-family: $f-manrope;
  font-size: rem-calc(24);
  font-weight: $font-weight_bold;
  line-height: 1.33em;
}

@mixin text-platform-h2 {
  font-family: $f-manrope;
  font-size: rem-calc(18);
  font-weight: $font-weight_bold;
  line-height: 1.44em;
}

@mixin text-platform-h3 {
  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_bold;
  line-height: 1.375em;
}

@mixin text-platform-h4 {
  font-family: $f-manrope;
  font-size: rem-calc(14);
  font-weight: $font-weight_bold;
  line-height: 1.36em;
}

@mixin text-platform-h5 {
  font-family: $f-manrope;
  font-size: rem-calc(10);
  font-weight: $font-weight_bold;
  line-height: 1.4em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@mixin line-clamp(
  $font-size,
  $line-height,
  $lines-to-show
) {
  display: block; // Fallback for non-webkit browsers
  display: -webkit-box;
  font-size: rem-calc($font-size);
  max-height: rem-calc(($font-size * $line-height * $lines-to-show)); // Fallback for non-webkit browsers
  line-height: $line-height;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines-to-show;
}

// redesign typography

@mixin text-landing-section-heading {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(12);
  color: $c-red;
  line-height: rem-calc(16);
  font-weight: $font-weight_bold;
  letter-spacing: rem-calc(0.36);
  text-transform: uppercase;
}

@mixin new-landing-h1 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(56);
  font-weight: $font-weight_bold;
  line-height: 1em;
  letter-spacing: rem-calc(-3.2);

  @include breakpoint(medium down) {
    font-size: rem-calc(44);
    letter-spacing: rem-calc(-2.2);
  }
}

@mixin new-landing-h2 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(52);
  color: $c-dark-blue;
  font-weight: $font-weight_bold;
  line-height: rem-calc(60);
  letter-spacing: rem-calc(-2);

  @include breakpoint(medium down) {
    font-size: rem-calc(36);
    line-height: rem-calc(40);
    letter-spacing: rem-calc(-1.2);
  }
}

@mixin new-landing-h3 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(40);
  font-weight: $font-weight_bold;
  line-height: rem-calc(48);
  letter-spacing: rem-calc(-2);

  @include breakpoint(medium down) {
    font-size: rem-calc(32);
    line-height: rem-calc(40);
  }

  @include breakpoint(small down) {
    font-size: rem-calc(28);
    line-height: rem-calc(32);
    letter-spacing: rem-calc(-1.2);
  }
}

@mixin new-landing-h4 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(32);
  font-weight: $font-weight_bold;
  line-height: rem-calc(40);
  letter-spacing: rem-calc(-2);

  @include breakpoint(medium down) {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
    letter-spacing: rem-calc(-1.2);
  }
}

@mixin new-landing-h5 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(20);
  font-weight: $font-weight_bold;
  line-height: rem-calc(26);
  letter-spacing: rem-calc(-1);

  @include breakpoint(medium down) {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    letter-spacing: normal;
  }
}

@mixin new-landing-h6 {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(18);
  font-weight: $font-weight_medium;
  line-height: rem-calc(26);
  letter-spacing: rem-calc(-0.5);

  @include breakpoint(medium down) {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
  }
}

@mixin new-landing-label-text {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(14);
  line-height: 1.4em;
  font-weight: $font-weight_semi-bold;
  letter-spacing: rem-calc(-0.28);
}

@mixin new-landing-text-secondary {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(18);
  font-weight: $font-weight_medium;
  line-height: rem-calc(26);
  letter-spacing: rem-calc(-0.36);
}

@mixin new-landing-text-main {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_medium;
  line-height: 1.5em;
  letter-spacing: rem-calc(-0.32);
}

// New landing typography | Headings
@mixin landing-heading-xsmall {
  @include hyphens-auto;

  font-family: $f-manrope;
  line-height: 1.3em;
  font-size: rem-calc(20);
  font-weight: $font-weight_bold;
  letter-spacing: -0.025em;
}


// New landing typography | Paragraphs
@mixin landing-paragraph-medium {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(16);
  font-weight: $font-weight_medium;
  line-height: 1.5em;
  letter-spacing: -0.02em;
}

@mixin heading-small {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(24);
  font-weight: $font-weight_bold;
  line-height: 1.25em;
  letter-spacing: -0.05em;
}

@mixin heading-xxsmall {
  @include hyphens-auto;

  font-family: $f-manrope;
  font-size: rem-calc(18);
  font-weight: $font-weight_bold;
  line-height: 1.333em;
  letter-spacing: -0.02em;
}

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:99839");